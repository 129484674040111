<template lang="pug">
.login-wrapper(v-loading="loading")
  .login-bg
    .login-container
      div.app-logo 大运河(北京)供水营收系统
      DataForm.login-form(
        v-model="loginData"
        size="large"
        label-position="top"
        buttonAlign="right"
        :formFields="loginFields"
        :buttonList="loginButtons")
  IdentityDialog(
    v-model="dialogVisable"
    :userIdentity.sync="userIdentity"
    @switchConfirm="handleSwitchConfirm")
</template>

<script>
import { cloneDeep } from 'lodash'
import { setToken, getUserInfo } from '@/api/auth'
import IdentityDialog from './components/IdentityDialog.vue'

export default {
  name: 'Login',
  components: {
    IdentityDialog
  },
  data () {
    return {
      loading: false,
      loginData: {},
      loginFields: [{
        name: 'loginName',
        label: '账号',
        form: {
          tag: 'input'
        }
      }, {
        name: 'loginPassword',
        label: '密码',
        form: {
          tag: 'input',
          password: true
        }
      }],
      loginButtons: [{
        label: '登录',
        type: 'primary',
        func: ({ formData }) => this.login(formData)
      }],
      token: null,
      dialogVisable: false,
      userIdentity: ''
    }
  },
  methods: {
    handleSwitchConfirm (userIdentity) {
      this.dialogVisable = false
      return this.login(this.loginData, userIdentity)
    },
    async login (data, userIdentity) {
      try {
        this.loading = true
        const { token, identifications } = await this.loginApi(data, userIdentity)
        this.$store.commit('menu/SET_CURRENT_IDENTITY_ID', userIdentity)
        if (identifications) {
          this.loading = false
          // 身份选择
          this.$store.commit('menu/SET_IDENTIFICATIONS', identifications)
          this.dialogVisable = true
          return 'identifySelect'
        } else {
          if (!token) return
          setToken(token)
          this.token = token
          await getUserInfo(this.routerToHome)
        }
      } catch (error) {
        this.loading = false
        return error
      }
    },
    loginApi (data, identificationId) {
      return new Promise((resolve, reject) => {
        const result = cloneDeep(data)
        if (!data.loginName || !data.loginPassword) {
          reject(new Error('login error: loginName and loginPassword is required'))
          return
        }
        result.loginName = result.loginName.trim()
        result.loginPassword = this.$md5(data.loginPassword)
        result.identificationId = identificationId || ''
        this.$post({
          url: '/wfbase/login',
          data: result
        })
          .then(loginResult => {
            if (!loginResult) {
              reject(loginResult)
            } else {
              resolve(loginResult.data || {})
            }
          })
          .catch(reject)
      })
    },
    routerToHome ({ authMenus }) {
      this.loading = false
      this.$message({
        message: '登录成功',
        showClose: true,
        type: 'success'
      })
      const { redirect } = this.$route.query
      if (redirect) {
        this.$router.replace({ path: redirect })
      } else {
        const path = authMenus[0]?.children?.[0]?.url
        this.$router.push(path || '/')
      }
    }
  },
  created () {
    document.onkeydown = (e) => {
      const key = window.event.keyCode
      if (key === 13) this.login(this.loginData)
    }
  },
  beforeDestroy () {
    document.onkeydown = null
  }
}
</script>
<style lang="sass" scoped>
@import 'src/assets/css/var.sass'
.login-wrapper
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-image: url('../../assets/imgs/backgroup.jpg')
  background-size: cover
  background-repeat: no-repeat
  // background-size: cover
  z-index: $--zindex-middle
.login-bg
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%
  overflow: auto
.login-container
  width: 370px
  display: flex
  flex-direction: column
  padding: 20px 35px
  background: rgba(255,255,255,0.8)
  position: absolute
  box-shadow: 0 0 20px -4px rgba(0, 0, 0, 0.1)
.app-logo
  font-size: 24px
  line-height: 60px
  color: #666
.subtext__small
  font-size: 10px
  text-align: center
  margin-top: 10px
.writeFarming
  background: linear-gradient(-45deg, #413c69, #4a47a3, #2d8cf0, #a7c5eb, #709fb0)
  animation: gradientBG 20s ease infinite
  background-size: 400% 400%

@keyframes gradientBG
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%
</style>
